.bottomloginbanner
{
  display: flex;
  align-items: center;
  width: 100%;
  bottom: 1.3vh;
  position: absolute;
  justify-content: center;
  .power{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 22px;

line-height: 44px;

color: #5F6163;
  }
  img{
    margin-left: 10px;
    cursor: pointer;
    &:hover{
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
}
.master1 {
  height: 100vh;
  display: flex;
  overflow: hidden;
  color: #5f6163;
  .iconMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 90px;
    padding: 25px;
    border-right: 0.25px solid #e5e5e5;
    .each-icon {
      .icon {
        opacity: 0.25;
        margin: 35px 0 35px 0;
        width: 40px;
        height: 40px;
      }
    }

    .each-icon :hover {
      opacity: 1;
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
}

.form {
  // height: 100vh;
  // width: calc((100vw - 70px)/2);
  // padding: 30px;
  // margin-top: 10%;
  display: table;
  top: 0;
  left: 0;
 
  height: 100vh;
  // width: calc((100vw - 90px) / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #161b21;
  width: 100%;
}

.loginForm {
  color: #5f6163;
  display: table-cell;
  vertical-align: middle;
  // padding-bottom: 2.5rem;
  // display: flex;
  // flex: 1 1;
  // flex-direction: column;
  // padding: 0 10%;
  // position: relative;
  .indianOTClogo {
    // width: 190px;
    margin-bottom: 1rem;
  }
  .group,
  .group1 {
    position: relative;
    margin: 0;
    display: flex;
    width: 322px;
    margin-top: 28px;
    input {
      width: 322px;
      line-height: 15px;
    }
    input[type="password"],
    input[type="text"] {
      background: none;
      color: #5f6163;
      font-size: 18px;
      padding: 20px;
      display: block;
      border: none;
      border-radius: 0;
      border: 0.5px solid #ffffff;
      border-radius: 8px;
      font-weight: 600;
      font-size: 18px;

      /* or 417% */

      color: #ffffff;

      opacity: 0.25;
      &:focus {
        outline: none;
      }
      &:focus ~ label,
      &:valid ~ label {
        top: -14px;
        font-size: 12px;
        color: #5f6163;
        font-weight: 700;
      }
    }
    input[type="password"] {
      // letter-spacing: 0.3em;
    }
    label {
      color: #5f6163;
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 10px;
      transition: 300ms ease all;
      opacity: 0.25;
    }
    .eye {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
      height: 20px;
      width: 20px;
      opacity: 0.25;
      cursor: pointer;
    }
  }
  .forgetpassword{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 75px;
/* or 469% */

display: flex;
align-items: center;
text-align: right;
width: 322px;
justify-content: end;
color: #5F6163;
line-height: 43px;
cursor: pointer;
&:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease-in-out;
}
  }
  .EmailRegister{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 35px;
margin-top: 1rem;
/* or 469% */
width: 322px;
display: flex;
align-items: center;
text-align: center;
cursor: pointer; 
color: #5F6163;
justify-content: center;
&:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease-in-out;
}
  }
  .loginbtn {
    height: 60.94px;
    background: #3296CA;
    color: white;
    border: 0;
    outline: none;
    border-radius: 15px;
    margin-top: 52px;
    font-size: 20px;
    font-weight: 600;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    width: 322px;
    &:active {
      box-shadow: 0 2px 15px #186ab3;
    }
  }
  .loginbtn::after {
    background: white;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 1;
    position: absolute;
    top: -50px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }

  .loginbtn:hover::after {
    left: 120%;
    transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 50;
  }
  .forgot {
    margin-top: 25px;
    font-weight: 600;
  }
}

.register-form {
  display: table;
  top: 0;
  left: 0;
  height: 100vh;
  width: 50%;
  // width: calc((100vw - 90px) / 2);
  background-color: #186ab3;
  padding: 136px;
  .reg-card {
    display: table-cell;
    vertical-align: middle;
  }
  .reg-head {
    font-weight: 700;
    font-size: 60px;
    line-height: 85px;
    color: white;
  }
}
.innerLogin {
  width: 471px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #16171A;
}
.reg-btn {
  margin-top: 30px;
  width: 268.26px;
  height: 66.94px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  // background-color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin-top: 4rem;
  &:active {
    box-shadow: 0 2px 25px white;
  }
}

.reg-btn::after {
  background: white;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 1;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: -10;
}

.reg-btn:hover::after {
  left: 120%;
  transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 50;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

.otclogo {
  z-index: 999;
  background-color: black;
  border-radius: 25px;
  height: 100vh;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  width: 100%;
  position: absolute;
  top:0;

  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .otclogoimg {
    width: 400px;
    height: 300px;
    animation: heartbeat 1.3s infinite;
  }
}

.logincard {
  margin-top: -335px;
  position: fixed;
  z-index: -1;
  opacity: 0.25;
}
