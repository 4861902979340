.ToastNofication {
  width: 304px;
  height: 86px;
  /* left: 1109px; */
  top: 18px;
  position: absolute;
  top: 13px;
  right: 1vw !important;
  background: #212A35;
  padding: 12px;

  .toastImageSection {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .crossClose {
    position: absolute;
    right: 14px;
    top: 10px;
  }

  .tradeSucess {
    margin-right: 10px;
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;

    margin: 0;
    color: #BEBEBE;
  }

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;


    color: #5F6163;

    margin-bottom: 0;
  }
}

.piechartlist {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.terminal-master {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: #5f6163;
}

.inputpairSearch {
  border: none;
  width: 100%;
  height: 3rem;
  background: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  /* or 208% */

  display: flex;
  align-items: center;

  color: #9EA2A7;

  opacity: 0.5;

}

.pair-item {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  /* or 167% */
  cursor: pointer;

  display: flex;
  align-items: center;

  color: rgba(255, 255, 255, 0.56);
  height: 3.2rem;

  &:hover {
    transform: translateY(-5px);
  }
}

.topTermial {
  margin-top: 76px;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #131316;
}

.terminal-nav {
  top: 0;
  left: 0;
  position: fixed;
  background-color: #161B21;
  display: flex;
  height: 63px;
  width: 100vw;
}

.terminal-body {
  display: flex;
  margin-top: 0px;
  height: calc(100vh - 137px);
  width: 100vw;
  background-color: #131316;
}

.terminal-graphical {
  width: 79vw;
  height: calc(100vh - 137px);

  padding-right: 1rem;
  background: #131316;
}

.terminal-nav-graphical {
  padding: 0 40px;
  height: 60px;
  width: 100%;
  display: flex;
  gap: 14vh;
  background: #161B21;
  border-radius: 8px;
}

.each-price1 {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.each-price {
  display: flex;

  flex-direction: column;
  justify-content: center;
}

.transaction-small-head {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.5;
  white-space: nowrap;
}

.transaction-value {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}

.transaction-type-head {
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
}

.terminal-trade {
  height: calc(100vh - 170px);
  width: 21vw;
  background: #161B21;
  margin-right: 2rem;
  margin-top: 1rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  border-radius: 8px 8px;
}

.trade-nav {
  display: flex;
  justify-content: space-between;
  //   padding: 11px 30px;
  position: relative;
  height: 49px;
  width: 100%;
}

.each-price2 {
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 13px;

  &:hover {
    font-weight: 700;
  }
}

.trade-body {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy-section {
  height: 50%;
  width: 100%;
  //   padding: 0 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Alltrade {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #5F6163;
  justify-content: center;
  cursor: pointer;
  height: 2.3rem;

  &:hover {
    transform: scale(1.1);
  }
}

.sell-section {
  height: 50%;
  width: 100%;
  //   padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-top: 2%;

}

.trade-head-div {
  display: flex;
  justify-content: space-between;
  padding-top: 1vh;
}

.trade-head-inner {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
}

.small-btn-1 {
  cursor: pointer;
  height: 25px;
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  border: 0.5px solid #5f6163;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: #5F6163;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background-color: black;
    color: white;
  }
}

.trade-head-div-2 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.head-2 {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
}

.trade-btns {
  width: 83.5%;
  height: 40px;
  display: flex;
  border: 0.5px solid #5f6163;
  border-radius: 10px;
}

.btn-1 {
  cursor: pointer;
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-2 {
  cursor: pointer;
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.trade-box-1 {

  height: 15%;
  display: flex;
  align-items: center;
  border: 0.5px solid #5f6163;
  border-radius: 10px;
  justify-content: space-between;
  padding-right: 1vw;
  padding-left: 1vw;
  margin-top: 3px;
  margin-bottom: 2vh;
}

.dropdownbox {
  border: 0.5px solid #5f6163;
  margin-left: 30px;
  height: 17%;
  width: 7.65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  margin-top: -62vh;
  z-index: 11;
  overflow: hidden;
  position: fixed;
  text-align: center;
}

.dropdownbox1 {
  border: 0.5px solid #5f6163;
  margin-left: 30px;
  height: 17%;
  width: 7.65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  margin-top: -27vh;
  z-index: 11;
  overflow: hidden;
  position: fixed;
  text-align: center;
}

.trade-box-part1 {
  display: flex;
  padding: 0 0 0 0vw;
  height: 100%;
  width: 35%;
  text-align: left;
  font-weight: 700;
  font-size: 15px;
  line-height: 13px;
  align-items: center;
  opacity: 0.5;
}

.trade-box-part1-new {
  cursor: pointer;
  border-right: 0.5px solid #5f6163;
  display: flex;
  padding: 0 0 0 0vw;
  padding-right: 1vw;
  height: 100%;

  text-align: left;
  font-weight: 700;
  font-size: 15px;
  line-height: 13px;
  opacity: 0.5;
  align-items: center;
  //   &:hover {
  //     background-color: #5f6163;
  //   }
}

.drop-value {
  cursor: pointer;
  border-bottom: 0.5px solid #5f6163;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 13px;

  &:hover {
    background-color: black;
    color: white;
  }
}

.trade-box-part2 {
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: right;
  border: none;
  font-weight: 400;
  font-size: 15px;
  background: transparent;
  height: 15px;
  line-height: 13px;
  height: 100%;
  margin-right: 12px;
  width: 100%;
  color: #5F6163;
}

.trade-box-part3 {
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: right;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
}

.submit-trade-btn {
  cursor: pointer;
  position: relative;
  margin-top: 2vh;
  width: 100%;
  height: 15%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: white;
  background: #3296CA;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  overflow: hidden;
}

.submit-trade-btn::before {
  background: #fff;
  bottom: 0;
  content: "";
  height: 91%;
  left: -70%;
  margin: auto;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  transform-origin: center center;
  width: 88%;
}

.submit-trade-btn:hover::before {
  left: 220%;
  transition: all 0.6s ease;
}

.submit-trade-btn1 {
  cursor: pointer;
  position: relative;
  margin-top: 2vh;
  width: 100%;
  height: 15%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: white;
  background: #ef5350;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  overflow: hidden;
}

.submit-trade-btn1::before {
  background: #fff;
  bottom: 0;
  content: "";
  height: 91%;
  left: -70%;
  margin: auto;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  transform-origin: center center;
  width: 88%;
}

.submit-trade-btn1:hover::before {
  left: 220%;
  transition: all 0.6s ease;
}

.terminal-card {
  width: 100%;
  height: calc(100vh - 137px);

  padding-bottom: 0rem;
  background: #131316;
  padding-top: 1rem;
}

.all-stats-box {
  width: 100%;
  height: 60%;
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
  border-radius: 8px;
}

.final-nav-box {
  width: 100%;
  height: calc(40% - 0px);
  gap: 1rem;
  display: flex;
}

.stats-1 {
  width: 72%;
  height: 100%;
  border-radius: 8px;
  background: #16171A;
}

.stats-2 {
  width: 28%;
  height: 100%;
  background: #161B21;
  border-radius: 8px;
}

.red-stats {
  height: 50%;
  //   border-bottom: 0.5px solid #5F6163;
}

.green-stats {
  height: calc(50% - 20px);
  // padding-top: 2vh;
  border-radius: 8px;
  overflow: hidden;
}

.stats-data-green {
  height: calc(100% - 2vh);

}

.stats-2-nav {
  display: flex;
  width: 100%;
  height: 28px;
  //   border-bottom: 0.5px solid #5F6163;
  font-weight: 700;
  font-size: 15px;
  line-height: 13px;
  border-radius: 0px 8px;
  padding: 11px 13px;
  position: relative;
  height: 49px;

  padding-left: 1.1rem;
  padding-right: 1.1rem;

}

.each-stat-nav {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-right: 9%;
  // text-align: center;
  // align-items: center;
}

.stat-head1 {
  //   padding-left: 2vw;
  text-align: left;
}

.stat-head2 {
  //   padding-left: 2vw;
  text-align: center;
}

.stat-head3 {
  text-align: right;
}

.price-stat-nav1 {
  width: 66.66%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.price-stat-nav {
  width: 100%;
  display: flex;

  justify-content: end;
  align-items: center;
  // margin-right: 2vw;
  text-align: right;
  font-weight: 700;
  font-size: 16px;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  line-height: 20px;
}

.each-stat-row {
  width: 100%;
  height: 20%;
  display: flex;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  align-items: center;
  // padding: 0 15% 0 0;
}

.stats-data {
  height: calc(100% - 64px);
  overflow: hidden;
  border-radius: 8px 0px;
}

.red-text-1a {
  color: #ef5350;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: 33.33%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // align-items: center;
}

.red-text-1b {
  color: #ef5350;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  width: 33.33%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.red-text-1c {
  color: #ef5350;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  width: 33.33%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.green-text-1a {
  color: #3296CA;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  width: 33.33%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;

}

.green-text-1b {
  color: #3296CA;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  width: 33.33%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.green-text-1c {
  color: #3296CA;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  width: 33.33%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.final-box1 {
  width: 36%;
  height: 100%;
  background-color: #161B21;
  background-color: #161B21;
  border-radius: 8px;
  height: calc(100% - 34px);
  overflow: hidden;
  //   border-right: 0.5px solid #5F6163;
}

.final-box2 {
  width: 36%;
  height: 100%;
  background-color: #161B21;
  border-radius: 8px;
  overflow: hidden;
  height: calc(100% - 34px);
  //   border-right: 0.5px solid #5F6163;
}

.final-box3 {
  width: 28.3%;
  height: 100%;
  background-color: #161B21;
  overflow: hidden;
  border-radius: 8px;
  height: calc(100% - 34px);
  //   border-right: 0.5px solid #5F6163;
}

.final-box-nav1 {
  height: 40px;
  //   border-bottom: 0.5px solid #5F6163;
  display: flex;
  background: #161B21;
  border-radius: 8px;
}

.final-box-nav2 {
  height: 40px;
  //   border-bottom: 0.5px solid #5F6163;
  display: flex;
  background: #161B21;
  border-radius: 8px;
}

.final-box-nav3 {
  height: 40px;
  //   border-bottom: 0.5px solid #5F6163;
  display: flex;
  border-radius: 8px;
  background: #161B21;
}

.final-text-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 50%;
  font-weight: 700;
  font-size: 15px;
  line-height: 13px;
  opacity: 0.25;
  cursor: pointer;
}

.final-box-body {
  flex: 1;
  overflow: scroll;
  height: calc(100% - 40px);
  width: 100%;
  padding: 0px 15px 0 15px;
  background-color: #161B21;
}

.each-news-card {
  cursor: pointer;
  display: flex;
  border-bottom: 0.5px solid #5f6163;

  &:hover {
    transform: scale(1.05);
    transition: ease 0.3s;
    background-color: #5f6163;
  }
}

.each-news-card-dark {
  cursor: pointer;
  display: flex;
  border-bottom: 0.5px solid #5f6163;

  &:hover {
    transform: scale(1.05);
    transition: ease 0.3s;
    background-color: #a29d9d !important;
    color: #5f6163;
  }
}

.each-news-box {
  margin-left: 10px;
  width: 70%;
}

.each-news-img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  align-items: center;
}

.each-news-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.each-news-title {
  padding: 10px 0;
  font-weight: 800;
  font-size: 15px;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.each-news-desc {
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.each-news-time {
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 9px;
  line-height: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pairs-popup {
  width: 401px;
  height: 509px;
  margin-top: 1%;
  z-index: 9;
  background-color: white;
  position: absolute;
  margin-left: 18.5vw;
  border-radius: 15px;
}

.pagemask {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden !important;
  position: fixed !important;
  right: 0;
  top: 0;
  z-index: 11;
}

//
.pairs-search-bar {
  display: flex;
  height: 45px;
  padding: 15px;
  width: 100%;
  border-bottom: 0.5px solid #5f6163;
  opacity: 0.25;

  .pairs-search-img {
    margin: 0px 5px;
  }

  .pairs-searchbox {
    width: 90%;
    border: none;
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
  }
}

.all-coins-data {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}

.coins-box {
  cursor: pointer;
  height: 30px;
  width: 70px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 5px;
  border: 0.5px solid #5f6163;
  border-radius: 5px;
  margin: 10px;
}

.pairs-coin-img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.pairs-coin-img-active {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.each-coin-pairs {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.all-pairs {
  cursor: pointer;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #5f6163;
  padding: 30px;
}

.all-pairs:hover {
  background-color: #5f6163;
}

.pair-boxes {
  display: flex;
  justify-content: space-between;
}

.each-pair-box {
  display: flex;
  width: 50%;
}

.pairInfo {
  overflow: scroll;
  height: calc(100vh - 317px);
}

.basecurrency {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 25px;
  /* or 250% */

  display: flex;
  align-items: center;

  color: #5F6163;

  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.each-pair-box1 {
  display: flex;
  justify-content: end;
  text-align: right;
  width: 50%;
}

.selektonloader::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, #000000, #000000, #000000) !important;
}

.borderTrading {
  height: 1px;
  width: 59%;
  background-color: #5f6163;
  position: absolute;
  bottom: 5px;
}

.each-pair-img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.each-pair-head {
  font-weight: 800;
  font-size: 15px;
  line-height: 25px;
}

.market-popup {
  margin-left: 51%;
  width: 401px;
  height: 509px;
  margin-top: 1%;
  z-index: 9;
  background-color: white;
  position: absolute;
  border-radius: 15px;
}

.market-menu {
  height: calc(100vh - 120px);
  width: 100%;
}

.each-market-box {
  cursor: pointer;
  height: 12%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  padding-left: 0px;

  // border-radius: 15px;
  &:hover {
    // background-color: #5f6163;
    opacity: 1;

    .market-menu-box {
      transform: scale(1.05);
      transition: 0.7s ease;
    }
  }
}

.each-market-box-dark {
  cursor: pointer;
  height: 14%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #5f6163;
  padding-left: 20px;

  // border-radius: 15px;
  &:hover {
    background-color: #a29d9d;
    color: #5f6163;
    opacity: 1;

    .market-menu-box {
      transform: scale(1.05);
      transition: 0.7s ease;
    }
  }
}

.market-menu-box {
  opacity: 0.25;
  display: flex;
  width: 80%;
}

.market-menu-img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.market-menu-name {
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
}

.routing-popup {
  margin-left: 61%;
  width: 401px;
  height: 204px;
  margin-top: 1%;
  z-index: 9;
  background-color: white;
  position: absolute;
  border-radius: 15px;
}

.each-routing-box {
  cursor: pointer;
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #5f6163;
  padding-left: 20px;

  // border-radius: 15px;
  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  &:hover {
    background-color: #5f6163;
    opacity: 1;

    .market-menu-box {
      transform: scale(1.05);
      transition: 0.7s ease;
    }
  }
}

.vaults-menu {
  position: relative;
  // animation: myfirst 1.5s;
  height: 100%;
}

// @keyframes myfirst {
//     0%   {left: 30vw; top: 0px;}
//     100% {left: 0; top: 0px;}
//   }

.vault-head-part {
  // border-bottom: 0.5px solid #5F6163;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  height: 60px;
}

.icon-part {
  display: flex;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    transition: ease 1s;
  }
}

.valutPageDrawer {
  height: calc(100vh - 327px);

  position: relative;
}

.innerDrawervault {
  overflow: scroll;
  height: calc(100vh - 29rem);
}

.backValut {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #5F6163;
  opacity: 0.9;
  border: 1px solid #5F6163;
  border-radius: 10px;
  height: 46px;
  position: absolute;
  width: 100%;
  cursor: pointer;
  bottom: 1rem;

  &:hover {
    transform: translateY(-5px);
  }
}

.closeValut {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #5F6163;
  opacity: 0.9;
  border: 1px solid #5F6163;
  border-radius: 10px;
  height: 46px;
  position: absolute;
  width: 100%;
  cursor: pointer;
  bottom: 5rem;

  &:hover {
    transform: translateY(-5px);
  }
}


.vault-img {
  height: 30px;
  width: 30px;
  margin-right: 3px;
}

.vaultSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 17px;
}

.search-vault-part {
  // height: 12%;
  border-top: 0.5px solid #5f6163;
  border-bottom: 0.5px solid #5f6163;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
}

.vault-searchbox {
  display: flex;
  height: 100%;
}

.vault-searchbox::-webkit-input-placeholder {
  opacity: 50%;
}

.coin-searchbox {
  display: flex;
  height: 100%;
  width: 98%;
  border: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  padding: 20px 30px;
}

.coin-searchbox::-webkit-input-placeholder {
  opacity: 50%;
}

.vault-img-box {
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.all-vaults-part {
  height: calc(100vh - 337px);
  overflow: scroll;
}

.selected-each-coin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
  height: 30%;
  border-bottom: 0.5px solid #5f6163;
  cursor: pointer;
}

.each-coin-data1 {
  display: flex;
  justify-content: space-between;
  height: 60%;
}

.each-coin-data {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  height: 12%;

  cursor: pointer;

  &:hover {
    // background-color: #5f6163;
    transform: translateY(-5px);
    // transform: scale(1.05);
    transition: ease 1s;
  }

  &:last-child {
    border-bottom: none;
  }
}

.each-coin-data-dark {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  height: 22%;
  border-bottom: 0.5px solid #5f6163;
  cursor: pointer;

  &:hover {
    background-color: #a29d9d;
    color: #5f6163;
    transform: scale(1.05);
    transition: ease 1s;
  }

  &:last-child {
    border-bottom: none;
  }
}

.each-vault-icon-part {
  display: flex;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  align-items: center;
}

.each-vault-coin-img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.coins-values-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.each-vault-coin-value {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  text-align: right;
}

.each-vault-coin-symbol {
  font-weight: 400;
  font-size: 10px;
  line-height: 25px;
}

.each-vault-coin-value_dc {
  font-weight: 400;
  font-size: 10px;
  line-height: 25px;
  text-align: right;
}

.vault-button-box {
  margin-top: 10px;
  width: 100%;
  display: flex;
  height: calc(40% - 10px);
}

.add-btn {
  border: 0.5px solid #3296CA;
  background: #3296CA;
  border-radius: 5px;
  height: 100%;
  width: 25%;
  margin-right: 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  overflow: hidden;
  position: relative;
}

.add-btn::before {
  background: #fff;
  bottom: 0;
  content: "";
  height: 65%;
  left: -90%;
  margin: auto;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  transform-origin: center center;
  width: 88%;
}

.add-btn:hover::before {
  left: 220%;
  transition: all 0.6s ease;
}

.send-btn {
  border: 0.5px solid #ef5350;
  background: #ef5350;
  border-radius: 5px;
  height: 100%;
  width: 25%;
  margin-right: 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  overflow: hidden;
  position: relative;
}

.send-btn::before {
  background: #fff;
  bottom: 0;
  content: "";
  height: 65%;
  left: -90%;
  margin: auto;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  transform-origin: center center;
  width: 88%;
}

.send-btn:hover::before {
  left: 220%;
  transition: all 0.6s ease;
}

.vault-transac-btn {
  border: 0.5px solid #5f6163;
  background: #ffffff;
  border-radius: 5px;
  height: 100%;
  width: calc(50% - 20px);
  margin-right: 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.vault-transac-btn::before {
  background: #5f6163;
  bottom: 0;
  content: "";
  height: 65%;
  left: -90%;
  margin: auto;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  transform-origin: center center;
  width: 88%;
}

.vault-transac-btn:hover::before {
  left: 220%;
  transition: all 0.6s ease;
}

.add-vault-menu {
  width: 100%;
}

.dropdown-icon {
  height: 10px;
  width: 10px;
  margin-left: 10px;
}

.pairs-box-new {
  height: 100%;
  margin-bottom: 1rem;
  width: 100%;
}

.coin-nav-new {
  display: -webkit-inline-box;
  width: 100%;
  overflow-x: scroll;
  height: 60px;
  border-bottom: 0.5px solid #5f6163;
}

.each-new-coin {
  cursor: pointer;
  display: flex;
  overflow: scroll;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 11px 13px;
  position: relative;
  height: 49px;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    opacity: 1;
    // border-bottom: 1px solid #5f6163;
  }
}

.borderPair {
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -1px;
  background: #5F6163;
}

.trading-flex-pair {
  display: flex;
  width: 100%;
  align-items: center;
}

.pairs-coin-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  position: relative;

  &:hover {
    transform: translateY(-5px);
  }
}

.pair-search-box-new {
  height: 12%;
  // border-top: 0.5px solid #5F6163;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.market-search-img {
  opacity: 0.5;
}

.market-search-box {
  display: flex;
  background: rgb(40, 49, 61);
  border-radius: 5px;
  border: none;
  width: 100%;
  margin-top: 1.3rem;
  position: relative;
  height: 3rem;
  padding: 0px 15px;
  padding: 0px 30px;
}

.market-searchbox::-webkit-input-placeholder {
  opacity: 50%;
}

.market-searchbox {
  width: 98%;
  border: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  background: transparent;
  margin-left: 10px;
  color: white;
}

.market-searchbox::-webkit-search-cancel-button {
  // position:relative;
  // right:20px;  

  // -webkit-appearance: none;
  // height: 20px;
  // width: 20px;
  // border-radius:10px;
  background: white !important;
  color: white !important;
}

.all-pairs-nav {
  border-bottom: 0.5px solid #5f6163;
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  width: 100%;
  padding: 10px 30px;
}

.pairs-nav-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
}

.all-new-pairbox {
  height: 100vh;
  overflow-y: scroll;
}

.profile-menu {
  height: 100%;
  width: 100%;
}

.settings-searchbox {
  height: 60px;
  border-bottom: 0.5px solid #5f6163;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.set-searchbox {
  width: 98%;
  border: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  padding: 16px 30px;
}

.set-searchbox::-webkit-input-placeholder {
  opacity: 25%;
}

.setting-menu-box {
  height: calc(100% - 60px);
  width: 100%;
}

.each-setting-menu {
  cursor: pointer;
  height: 16.666%;
  width: 100%;
  border-bottom: 0.5px solid #5f6163;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;

  &:hover {
    transform: scale(1.01);
    transition: ease 1s;
    background-color: #5f6163;
  }
}

.each-trade-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #5f6163;
  padding: 6px 9px;
  padding-bottom: 18px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    transition: ease 0.3s;
    // background-color: #5f6163;
  }
}

.each-trade-card-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.5px solid #5f6163;
  padding: 17px 0px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    transition: ease 0.3s;
    background-color: #a29d9d !important;
    color: #5f6163;
  }
}

.each-trade-menu {
  display: flex;
  justify-content: space-between;
}

.each-trade-title {
  font-weight: 800;
  font-size: 15px;
  line-height: 45px;
}

.each-trade-desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.setting-head {
  font-weight: 800;
  font-size: 20px;
  line-height: 35px;
}

.setting-desc {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.market-nav {
  height: 44px;
  width: 100%;
  padding: 0 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;

}

@media screen and (max-height: 750px) {
  .small-btn-1 {
    cursor: pointer;
    height: 25px;
    font-weight: 700;
    font-size: 9px;
    color: #5F6163;
  }

  .head-2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
  }
}


@media only screen and (min-width: 900px) and (max-width: 1400px) {
  .terminal-graphical {
    width: 74vw;
  }

  .terminal-trade {
    // height: calc(100vh - 170px);
    width: 26vw;
  }

  .stats-1 {
    width: 64%;
  }

  .stats-2 {
    width: 36%;
  }

  .final-box1 {
    width: 32%;
  }

  .final-box2 {
    width: 32%;
  }

  .final-box3 {
    width: 36.3%;
  }

  .terminal-nav-graphical {
    // padding: 0 40px;
    height: 60px;
    // width: 100%;
    // display: flex;
    gap: 9vh;
    overflow: scroll;

  }
}


@media only screen and (max-height: 695px) {

  .terminal-body {
    height: auto
  }

  .terminal-graphical {
    height: auto;
    overflow: scroll;
  }

  .terminal-card {
    width: 100%;
    height: 600px;
  }

  .terminal-trade {
    height: calc(100vh - 170px);
    overflow: scroll;
  }

  .trade-box-1 {
    height: 3rem;
  }

  .sell-section {
    height: auto;
  }

  .final-nav-box {
    width: 100%;
    height: 300px;
  }

  .buy-section {
    height: auto;
  }

  .final-box1 {
    width: 36%;
    // height: 100%;
    // background: #16171A;
    // border-radius: 8px;
    height: 300px
  }

}


@media only screen and (min-width: 200px) and (max-width: 900px) {
  .terminal-nav-graphical {
    height: auto;
    /* overflow: scroll; */
    /* flex-direction: column; */
    flex: 1 1;
    padding-top: 1rem;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;
    align-items: center;
    padding-top: 1rem;
  }

  .final-box-body {
    flex: 1 1;
    overflow: visible;
    height: auto;
    width: 100%;
    padding: 0;
    background-color: #16171A;
  }

  .bottomMobileCustom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    position: fixed;
    bottom: 0;
    background: black;
    width: 100%;
    align-items: center;
    padding: 0 5%;
    z-index: 9;
    gap: 1rem;
  }

  .terminal-nav {
    z-index: 999;
    height: auto;
  }

  .topTermial {
    margin-top: 95px;
    padding: 0 5%;
  }

  .terminal-card {
    width: 100%;
    height: auto;
  }

  .terminal-master {
    height: 100vh;
    overflow: scroll;
  }

  .stats-2 {
    width: 100%;
  }

  .stats-1 {
    width: 100%;
    height: 204px;
    border-radius: 8px;
    background: #16171A;
  }

  .all-stats-box {
    width: 100%;
    height: auto;
    display: block;
  }

  .terminal-trade {
    height: calc(100vh - 100px);
    width: 100%;
    background: #161B21;
    margin-right: 0;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    border-radius: 8px 8px;
    // position: absolute;
    padding-top: 95px;
    top: 90px;
    right: 0;
    width: 100%;
    padding: 0 5%;

    padding-top: 18px;
    z-index: 9;
    position: fixed;
  }

  .trade-body {
    height: auto;
  }

  .buy-section {
    height: auto;
  }

  .trade-box-1 {
    height: 3rem;
  }

  .sell-section {
    height: auto;
  }

  .stats-2 {
    height: 20rem;
  }

  .submit-trade-btn1 {
    cursor: pointer;
    position: relative;
    margin-top: 2vh;
    height: 3rem;
    width: 100%;
    margin-bottom: 2rem
  }

  .submit-trade-btn {
    cursor: pointer;
    position: relative;
    margin-top: 2vh;
    width: 100%;
    height: 3rem;
  }

  .terminal-body {
    display: block;
    margin-top: 0px;
    height: auto;
    width: 100%;
  }

  .terminal-graphical {
    width: 100%;
    height: auto;
    padding-right: 0;
    background: #16171A;
  }

  .final-box1 {
    display: none;
  }

  .final-box2 {
    display: none;
  }

  .final-box3 {
    width: 100%;
    height: auto;
    background: #16171A;
    // overflow: scroll;
    border-radius: 8px;
    // height: 200px;
  }

  .final-nav-box {
    width: 100%;
    height: auto;
    gap: 1rem;
    //display: none;

  }

  .set-searchbox {
    width: 100%;
    border: none;
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
    padding: 16px 30px;
    background: transparent;
    // border: 1px solid #fff;
  }

  .each-setting-menu {
    cursor: pointer;
    height: 4rem;
    width: 100%;
    border-bottom: 0.5px solid #5f6163;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }

  .setting-head {
    font-weight: 800;
    font-size: 15px;
    line-height: 17px;
  }

  .pngwrngicon {
    cursor: pointer;
    width: 20px;
    position: absolute;
    right: 5%;
    top: 10px;
  }
}