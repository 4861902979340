.add-vault-logo-box{
    display: flex;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0px 30px;
    cursor: pointer;
    height: 60px;
    border-bottom: 0.5px solid #E7E7E7;
    &:hover{
        transform: scale(1.05);
        transition: ease 1s;
    }
}



.add-vault-main-img{
    height: 30px;
    width: 30px;
    margin-right: 3px;
}
.add-vault-main-name{
    // font-weight: 700;
    // font-size: 30px;
    // line-height: 37px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
}

.menu1{
    padding: 20px 30px;
}

.subhead{
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.subhead1{
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
}

.transaction {
    margin: 20px 0px 20px 0px;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.transc{
    border: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #5F6163;
    opacity: 1;
    width: 130px;
}
.transc::placeholder {
    opacity: 0.25;
  }
  
.transc:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0.25;
}
  
.transc::-ms-input-placeholder { /* Microsoft Edge */
    opacity: 0.25;
}

.transc::-webkit-outer-spin-button,
.transc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.transc[type=number] {
  -moz-appearance: textfield;
}
.coin{
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 150px;
    height: 32px;
    left: 206px;
    top: 304px;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    padding: 10px;
}
.coin1{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 150px;
    height: 32px;
    left: 206px;
    top: 304px;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
}
.coin:hover, .coin1:hover {
    background-color: black;
    color: white;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}

.coinsymbolsmall {
    font-weight: 800;
    font-size: 11px;
    line-height: 13px;
    margin-left: 5px;
}

.perc {
    cursor: not-allowed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 32px;
    left: 39px;
    top: 382px;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.25;
}
.perc:hover{
    color: #FFFFFF;
    background: black;
}

.footer1{
    background-color: white;
    border-top: 1px solid #E7E7E7;
    border-right: 1px solid #E7E7E7;
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 22%;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    .back{
        height: 60px;
        width: 50%;
        padding: 20px;
        color: #5F6163;
        background-color: white;
        text-align: center;
        cursor: pointer;
    }
    .tradebtn{
        height: 60px;
        width: 50%;
        padding: 20px;
        background-color: #5F6163;
        color: rgba(209, 206, 206, 0.782);
        text-align: center;
        cursor: not-allowed;
        opacity: 0.3;
    }
    
}

.select-box{
    padding: 20px 30px;
}

.select-title-head{
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.search-card{
    width: 100%;
    height: 100%;
    border: 0.5px solid #EEEEEE;
    margin-top: 20px;
    border-radius: 15px;
}

.searchboxasset{
    height: 20%;
    width: 100%;
    border-bottom: 0.5px solid #EEEEEE;
    padding: 20px;
    .search{
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #5F6163;
        border: none;
        width: 90%;
    }
    .search::-webkit-input-placeholder {
        opacity: 0.5;
    }
}

.assetlistcard{
    height: 53vh;
    overflow-y: scroll;
}

.coin-box{
    display: flex;
    align-items: center;
    height: 25%;
    padding: 20px;
    border-bottom: 0.5px solid #EEEEEE;
    cursor: pointer;
}
.coin-box:hover{
    background-color: #f5f5f5;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}

.coin-box-dark{
    display: flex;
    align-items: center;
    height: 25%;
    padding: 20px;
    border-bottom: 0.5px solid #EEEEEE;
    cursor: pointer;
}
.coin-box-dark:hover{
    background-color: #a29d9d;
    color: #E7E7E7; 
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}
.coin-Image{
    height: 25px;
    width: 25px;
    margin-right: 10px;
}
.coin-Title{
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    // margin: 5px 0 0 15px;
}


.terminallogo{
    width: 100%;
    z-index: 999;
    background-color: white;
    border-radius: 25px; 
    margin-top: -55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .terminallogoimg{
      width: 300px;
      height: 400px;
      animation: heartbeat 1.3s infinite;
    }
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

.success-vault-box{
    padding: 20px 30px;
}

.success-text{
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
}

.success-head{
    margin-top: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.updated-value{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}