.navbar-master {
  // top:0;
  // left:0;
  // position: fixed;
  // background-color: #16171A;
  // display: flex;
  // height: 70px;
  // width: 100vw;

  // color: #5F6163;
  background-color: #131316;
  display: flex;
  height: 76px;
  width: 100vw;
  padding-top: 8px;
  color: #5f6163;
  padding-bottom: 8px;
  justify-content: space-between;
}

.NameInfoAI {
  margin-left: 2px;
  margin-right: 4px;
}

.InfovaultSelect {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  color: #5F6163;
  white-space: nowrap;
}

.valutid {
  width: 72px;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;

  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: #5F6163;
}

.nav-logo-box {
  cursor: pointer;
  // padding: 15px;
  // width: 15%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.nav-logo {
  // height: 80%;
  height: 38px;
  padding-left: 30px;
  margin-right: 30px;
}

.nav-logo-box:hover {
  .nav-logo {
    transform: scale(1.1);
    transition: 1s ease;
  }
}

.nav-search-menu {
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  width: 376px;
  margin: 0.5%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.5px solid #e7e7e7;
}

.nav-search-box {
  width: 100%;
  display: flex;
}

.search-nav-img {
  width: 16px;
  opacity: 0.5;
  margin-right: 7px;
  margin-right: 7px;
  // background-color: #F9F9F9;
}

.search-nav-text {
  height: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: #5f6163;
  opacity: 0.5;
}

.nav-menu-box {
  cursor: pointer;
  width: 55%;
  display: flex;
  align-items: center;
}

.nav-menuitem {
  padding: 15px;
  width: 25%;
  text-align: center;
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
  color: #5f6163;
  opacity: 1;
}

.nav-menu-img {
  height: 17px;
  width: 17px;
  margin-right: 5px;
}

.nav-menuitem:hover {
  // .nav-menu-text{
  //     font-weight: 700;
  // }
  opacity: 1;
  transform: scale(1.05);
  transition: 0.5s ease;
}

.nav-logo-section {
  display: flex;
  align-items: center;
}

.fliterlogout {
  cursor: pointer;
  margin-left: 15px;
}

.nav-menu-action {
  width: 20%;
  display: flex;
}

.nav-menu-actionLast {
  display: flex;
  align-items: center;
  background: #16171A;
  margin-right: 20px;
  // padding-right: 15px;
  height: 46px;
  border-radius: 9px;
  margin-left: 11px;
}

.nav-action-menuItem1 {
  cursor: pointer;
  padding: 15px;

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  /* padding-right: 30px; */
  padding-right: 8px;


}

.user-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.nav-action-menuItem1:hover {
  .user-icon {
    transform: scale(1.1);
    transform: 1s ease;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1400px) {

  .nav-search-menu {

    max-width: 224px;
  }

  .nav-menuitem {
    // padding: 15px;
    width: auto;
  }

  .search-nav-text {
    white-space: nowrap;
  }

  .nav-menu-box {
    cursor: pointer;
    width: auto;
  }

  .nav-menu-actionLast {

    margin-left: 0px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 900px) {
  .navbar-master {
    display: block;
    height: auto;
    padding: 3% 5%;
  }

  .nav-logo-section {
    justify-content: space-between;
  }

  .nav-logo {
    height: 38px;
    padding-left: 0;
    margin-right: 18px;
  }

  .nav-menuitem {
    // padding: 15px;
    width: auto;
    padding: 8px 0px
  }

  .nav-search-menu {
    cursor: pointer;
    padding: 7px;
    background-color: #f9f9f9;
    width: 80%;
    margin: 0.5%;
    display: none;
  }

  .nav-menu-actionLast {
    margin: 0;
  }

  .nav-menu-box {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    height: auto;
    background: #16171A;
    gap: 2rem;
    overflow: scroll;
    padding: 0 0%;
  }
}