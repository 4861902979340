.toggle-icon-part{
	align-items: center;
    display: flex;
    justify-content: end;
}

.tgl1[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.tgl2 {
    border: 3px solid #28313d;
	cursor: pointer;
	text-indent: -9999px;
	width: 30px;
	height: 18px;
	background: #28313d;
	display: block;
	border-radius: 100px;
	position: relative;
}

.tgl2:after {
	content: '';
	position: absolute;
	top: -1px;
	left: 1px;
	width: 14px;
	height: 14px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.tgl1:checked + .tgl2 {
	background: rgb(8, 170, 67);
}

.tgl1:checked + .tgl2:after {
	left: calc(100%);
	transform: translateX(-100%);
}


.tgl2:active:after {
	width: 20px;
}