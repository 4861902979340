.select-master{
    margin: 100px 0px;
    // padding: 0 10%;
    overflow: hidden;
    width: 100vw;
    background: #FFFFFF;
}

.selectmaincontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: calc(100vw);
    background: #FFFFFF;
}

.selectmaintext{
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    margin-top: 50px;
}

.app-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #FFFFFF;
    margin: 0 5%;
    width: calc(100vw);
}

.selectappcontainer{
    margin-top: 30px;
    width: 70%;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.select-app-card{
    cursor: pointer;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    margin-right: 20px;
    // width: 329px;
    width: calc(33.33% - 40px);
    height: 160px;
    background: #FFFFFF;
    border: 0.5px solid #EBEBEB;
    margin-bottom: 5px;
}

.select-app-card:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: scale(1.05);
    transition: all 1s ease;
}

.select-app-text{
    margin-top: 20px;
    width: 100%;
}

.select-app-img{
    height: 100px;
    width: 100%;
}

@keyframes newbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

.selectlogo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background-color: white;
    border-radius: 25px; 
    .selectlogoimg{
        height: 40vh;
        width: 40vw;
      animation: newbeat 1.3s infinite;
    }
}

